<template>
  <k-crud-layout :search.sync="search" :sheet-color="null">
    <template #header>
      <router-link :to="{name: 'catalog.category'}" class="secondary--text">{{ $t('catalog.pageTitle') }}</router-link>
      / {{ $t('catalog.product.pageTitle', [category.name]) }}
    </template>
    <template #view.wall>
      <div v-if="paginator.data.length === 0">
        {{ $t('catalog.product.noProducts') }}
      </div>
      <k-display-group v-else language-prefix="catalog.product.fields">
        <v-container class="mt-6 pa-0">
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="3"
                   v-for="({id,image,name,price,localPrice,sku,dimensions,description}) in paginator.data"
                   v-bind:key="id">
              <v-card flat tile class="fill-height d-flex flex-column">
                <div class="img-container pa-1" >
                  <VImg :src="`${image}&type=small`"
                        :lazy-src="`${image}&type=lazy`"
                        max-height="100%"
                        max-width="100%"
                        contain
                        @click="openImage(image)"
                  />
                </div>
                <v-card-title class="pt-2">
                  <span class="text-h3 text-break font-weight-bold">{{ name }}</span>
                </v-card-title>
                <v-card-text>
                  <k-text-display width="100px" horizontal field="salesPrice">
                    {{ $n(price / 100, 'currency') }}
                    <LocalePrice :price="(localPrice / 100)" badge-class="ml-2"/>
                  </k-text-display>
                  <k-text-display width="100px" horizontal field="itemCode">{{ sku }}</k-text-display>
                  <k-text-display width="100px" horizontal field="dimensions">{{ dimensions }}</k-text-display>
                  <p class="mt-4">{{ description }}</p>
                </v-card-text>
                <VSpacer/>
                <v-card-actions>
                  <VSpacer/>
                  <v-btn color="primary"
                         :loading="isAddToCartLoading === id"
                         @click="onAddToCartClick({productId: id, productName: name})"
                         :disabled="isAddToCartLoading !== false && isAddToCartLoading !== id"
                         depressed
                         tile>
                    {{ $t('catalog.product.actions.addToOrder') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <KBtn :disabled="prevBtnDisabled"
                    @click="currentPage -= 1"
                    outlined
                    :loading="isLoadingProducts"
                    class="mr-2">
                <v-icon left>$prev</v-icon>
                {{ $t('actions.previousPage') }}
              </KBtn>
              <KBtn :disabled="nextBtnDisabled" @click="currentPage += 1" :loading="isLoadingProducts" outlined>
                {{ $t('actions.nextPage') }}
                <v-icon right>$next</v-icon>
              </KBtn>
            </v-col>
          </v-row>
        </v-container>
      </k-display-group>
    </template>
  </k-crud-layout>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import Paginator from '@/application/mixins/Paginator.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import KTextDisplay from '@/components/crud/display/KTextDisplay.vue';
import KBtn from '@/components/KButton.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import { addToShoppingCart, categoryDetails, productList } from '@/modules/catalog/api';
import { mapActions, mapGetters } from 'vuex';
import LocalePrice from '@/components/LocalePrice.vue';

const page = 1;
const perPage = 12;

export default {
  name: 'ProductOverview',
  mixins: [Paginator],
  components: {
    LocalePrice,
    KBtn,
    KDisplayGroup,
    KTextDisplay,
    KCrudLayout,
  },
  data() {
    return {
      search: '',
      category: {},
      initialDataLoaded: false,
      isAddToCartLoading: false,
      isLoadingProducts: false,
    };
  },
  computed: {
    ...mapGetters('settings', { currentStoreId: 'getCurrentStoreId' }),
    currentPage: {
      set(value) {
        this.paginator.meta.currentPage = value;
      },
      get() {
        return this.paginator?.meta?.currentPage || 0;
      },
    },
    prevBtnDisabled() {
      if ('meta' in this.paginator) {
        if ('currentPage' in this.paginator.meta) {
          return this.paginator.meta.currentPage <= 1;
        }
      }
      return true;
    },
    nextBtnDisabled() {
      if ('meta' in this.paginator) {
        if ('currentPage' in this.paginator.meta) {
          return this.currentPage === this.paginator.meta.lastPage;
        }
      }
      return true;
    },
  },
  watch: {
    'paginator.meta.currentPage'(value) {
      this.isLoadingProducts = true;
      try {
        if (this.initialDataLoaded) this.updatePaginationPage(value);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.productList'),
        });
      }
      this.isLoadingProducts = false;
    },
  },
  async beforeRouteEnter(to, from, next) {
    const { params: { categoryId } } = to;
    const { data: { data: categoryData } } = await categoryDetails(categoryId);
    const { data: productsData } = await productList(categoryId, page, perPage);
    next(vm => {
      vm.setCategoryName(categoryData);
      vm.setProducts(productsData, page, perPage);
    });
  },
  methods: {
    ...mapActions('shoppingCart', ['fetchAmountInCart']),
    openImage(img){
      EventBus.$emit('expandImage', { imgUrl: img });
    },
    indexRequestHandler(...args) {
      // indexRequest is a prop, which is set within the router route.
      return this.indexRequest(this.category.id, ...args);
    },
    setCategoryName(data) {
      this.category = data;
    },
    async setProducts(paginatorData, page, perPage) {
      this.pagination = {
        ...this.pagination,
        page,
        perPage,
      };
      this.paginator = { ...this.paginator, ...paginatorData };
      // without the nextTick, the currentPage watcher would trigger due to state update
      // and thus, the page would be requested once more
      await this.$nextTick();
      this.initialDataLoaded = true;
    },
    async onAddToCartClick({
      productId,
      productName,
    }) {
      this.isAddToCartLoading = productId;
      try {
        await addToShoppingCart(this.currentStoreId, {
          productId,
          quantity: 1,
        });
        EventBus.$emit('snackbar', {
          color: 'success',
          text: this.$t('catalog.product.messages.addedToCart', [productName]),
        });
        await this.fetchAmountInCart(this.currentStoreId);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.addToCart', [productName]),
        });
      }
      this.isAddToCartLoading = false;
    },
  },
};
</script>
<style  lang="scss">
.img-container {
  height: 100px;
  cursor:pointer;
}
</style>
